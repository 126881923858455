<template>
	<div class="w-100 h-100 d-flex flex-column justify-content-between align-items-center">
		<b-avatar rounded :variant="`light-${color}`" size="36">
			<feather-icon :icon="icon" />
		</b-avatar>
		<div class="truncate text-center">
			<h2 class="mb-0 font-weight-bolder">
				{{ statistic }}
			</h2>
			<small>{{ statisticTitle }}</small>
		</div>
	</div>
</template>

<script>
import { BAvatar, BCard } from "bootstrap-vue";

export default {
	components: {
		BCard,
		BAvatar,
	},
	props: {
		icon: {
			type: String,
			required: true,
		},
		statistic: {
			type: [Number, String],
			required: true,
		},
		statisticTitle: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "primary",
		},
	},
};
</script>
